import React, { useContext, useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import $axios from "../axios";
import { Link, useParams } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import ReactPaginate from "react-paginate";

const ProductArea = () => {

  const {id} = useParams();
  const form = useRef();
  const settings = useContext(GeneralSettingsContext);
  const [formData, setFormData] = useState({});

  const [cat, setCat] = useState([]);
  const [product, setProduct] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [toResult, setToResults] = useState(0);
  const [searchData, setSearchData] = useState('');

  useEffect(() => {

    fetchData();
    fetchProduct();    
  }, [currentPage,searchData,id,formData]);

  const fetchData = async () => {
    try {
      const response = await $axios.get('/product-category');
      setCat(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchProduct = async () => {
    try {
      const response = await $axios.get(`/product?page=${currentPage + 1}&search=${searchData}&cat_id=${typeof id !== 'undefined'? id:''}&orderby=${typeof formData?.orderby !== 'undefined'? formData.orderby:''}`);
      setProduct(response.data);
      setPageCount(response.data.last_page);
      setTotalResults(response.data.total);
      setToResults(response.data.data.length);
      // setPostsPerPage(resp.data.per_page);

    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
   
  };

  const handlePageClick = (data) => {
      setCurrentPage(data.selected);
  };

  const handleSearchSubmit = async (e) => {
      e.preventDefault();
      const searchData = e.target.elements.search.value;
      setSearchData(searchData);
      setCurrentPage(0);
      fetchProduct();
  }

  if (!settings) {
    return null;
  }

  return (
    <>
     
      <Toaster position='bottom-center' reverseOrder={false} />

      <section className="about-area pd-top-120 pd-bottom-120">
        <div className="container">
            <div className="row flex-row-reverse">
                <div className="col-xl-9 col-lg-8">
                    <div className="shop-sort-bar">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md">
                                <p className="woocommerce-result-count">Showing 1-{toResult} of {totalResults} results</p>
                            </div>

                            <div className="col-md-auto">
                                <div className="form-group mb-0">
                                    <select name="orderby" className="single-select orderby" onChange={handleChange}>
                                        <option value="menu_order" selected="selected">Default Sorting</option>
                                        <option value="rating">Sort by average rating</option>
                                        <option value="date">Sort by latest</option>
                                        <option value="price">Sort by price: low to high</option>
                                        <option value="price-desc">Sort by price: high to low</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row gy-4">
                      {product.data && product.data.map((data, index) => (
                        <div key={index} className="col-xl-4 col-md-6">
                          <div className="product-card style2">
                            <div className="product-img">
                              <img src={data.thumb_image_url} alt={`Product ${index}`} />
                            </div>
                            <div className="product-content">
                              <h3 className="product-title"><Link to={`/product-details/${data.slug}`}>{data.name}</Link></h3>
                              
                              <span className="star-rating">
                                {[...Array(data.review_start)].map((_, i) => (
                                  <FaStar key={i} />
                                ))}
                              </span>

                              <span className="price">{getSettingValueByKey(settings,'currency')} {data.price} {data.discount_amount > 0 && <del>{getSettingValueByKey(settings,'currency')} {data.discount_amount}</del>}</span>
                              <Link to={`/product-details/${data.slug}`} className="link-btn">Add to cart <FaArrowRight/></Link>
                            </div>
                          </div>
                        </div> 
                      ))}
                    </div> 

                    <div className="pagination justify-content-center mt-70">
                    <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination justify-content-center mt-3'} // Align pagination to the right
                                    activeClassName={'active'}
                                    previousLinkClassName={'page-link'} // Apply Bootstrap page-link class to previous link
                                    nextLinkClassName={'page-link'} // Apply Bootstrap page-link class to next link
                                    pageClassName={'page-item'} // Apply Bootstrap page-item class to each page item
                                    previousClassName={'page-item'} // Apply Bootstrap page-item class to previous button
                                    nextClassName={'page-item'} // Apply Bootstrap page-item class to next button
                                    pageLinkClassName={'page-link'} // Apply Bootstrap page-link class to each page number link
                                    breakClassName={'page-item'} // Apply Bootstrap page-item class to break element
                                    breakLinkClassName={'page-link'} // Apply Bootstrap page-link class to break link
                                />
                    </div>

                </div>
                <div className="col-xl-3 col-lg-4 sidebar-widget-area">
                    <aside className="sidebar-sticky-area sidebar-area sidebar-shop">
                        <div className="widget widget_search">
                            <h3 className="widget_title">Search</h3>
                            <form className="search-form" onSubmit={handleSearchSubmit}>
                                <input type="text" name="search" placeholder="Find your product"/>
                                <button type="submit"><FaSearch/></button>
                            </form>
                        </div>

                        <div className="widget widget_categories  ">
                            <h3 className="widget_title">Product categories</h3>
                            <ul>
                              {cat.map((data,index)=>(
                                <li key={index}><Link to={`/product-category/${data.id}`}>{data.name}</Link><span>({data.products_count})</span></li>
                              ))}
                            </ul>
                        </div>
        
                    </aside>
                </div>
            </div>
        </div>
      </section>  
     
    </>
  );
};

export default ProductArea;
