import React, { useContext, useEffect, useState } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { fetchSteps } from "../scripts/stepList";

const WorkProcessOne = () => {
  const settings = useContext(GeneralSettingsContext);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchSteps();
      setSteps(data);
     
    };

    fetchData();
  }, []);

 if (!settings) {
  return null;
  }
  
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className='work-process-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>{getSettingValueByKey(settings,'step_head')}</h6>
            <h2 className='title'>
            {getSettingValueByKey(settings,'step_title')}
            </h2>
          </div>
          <div className='row'>
            
            {steps.map((data,index)=>(
            <div key={index} className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src={data.image_url} alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>{data.step_name}</p>
                  <h5 className='mb-3'>{data.name}</h5>
                  <p className='content'>
                    {data.detail}
                  </p>
                </div>
              </div>
            </div>
            ))}
          
            
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
