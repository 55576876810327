import React, { useContext, useEffect, useState } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { fetchGallery } from "../scripts/galleryList";
import { getSettingValueByKey } from "../utils/settingsUtils";

const CaseStudyAreaOne = () => {

  const settings = useContext(GeneralSettingsContext);
  const [gallery, setGalleries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchGallery();
      setGalleries(data);
     
    };

    fetchData();
  }, []);

  if (!settings) {
    return null;
  }

  return (
    <>
      {/*================== case-study area start ==================*/}
      <div className='case-study-area bg-relative pd-top-110'>
        <img
          className='animate-img-1 top_image_bounce'
          src='assets/img/banner/2.png'
          alt='img'
        />
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>{getSettingValueByKey(settings,'gallery_head')}</h6>
            <h2 className='title'>
            {getSettingValueByKey(settings,'gallery_title')}
            </h2>
          </div>
          <div className='row'>
            {gallery.map((data,index) => (
              <div key={index} className='col-lg-4 col-md-6'>
                <div className='single-case-study-inner'>
                  <div className='thumb'>
                    <img src={data.image_url} alt='img' />
                  </div>
                  <div className='details'>
                    <h5>
                      <Link to={`/gallery/${data.id}`}>{data.name}</Link>
                    </h5>

                    <Link className='cat' to={`/gallery/${data.id}`}>{data.short_detail.slice(0,50)}</Link>
                    <Link className='right-arrow' to={`/gallery/${data.id}`}><FaAngleDoubleRight /></Link>
                    
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
      {/* ================== case-study area end ==================*/}
    </>
  );
};

export default CaseStudyAreaOne;
