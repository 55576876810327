// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App";
// import "./index.scss";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import { GeneralSettingsProvider } from "./contexts/GeneralSettingsContext";
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <GeneralSettingsProvider>
//       <App />
//     </GeneralSettingsProvider>
//   </React.StrictMode>
// );


import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { GeneralSettingsProvider } from "./contexts/GeneralSettingsContext";

ReactDOM.render(
  <React.StrictMode>
    <GeneralSettingsProvider>
      <App />
    </GeneralSettingsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
