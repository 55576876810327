import React, { useContext, useEffect, useState } from "react";
import {
  FaArrowRight,
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { fetchSocial } from "../scripts/socialList";
import { fetchServices } from "../scripts/serviceList";
import { fetchSection } from "../scripts/sectionList";
import $axios from "../axios";
import { toast, Toaster } from "react-hot-toast";

const FooterOne = ({webimage}) => {

  const settings = useContext(GeneralSettingsContext);
  const [social, setSocial] = useState([]);
  const [service, setServices] = useState([]);
  const [section, setSection] = useState([]);
  const appUrl = process.env.REACT_APP_URL;
  const [formData, setFormData] = useState({ email: ''});
  const [subMsg, setsubMsg] = useState('');

  useEffect(() => {
    fetchSocialData();
    fetchServiceData();
    fetchSectionData();
  }, []);

  const fetchSocialData = async () => {
    const data = await fetchSocial();
    setSocial(data);
  };
  
  const fetchServiceData = async () => {
    const data = await fetchServices();
    setServices(data);
  };

  const fetchSectionData = async () => {
    const data = await fetchSection();
    setSection(data);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubSubmit = async (e) => {

    try {
      const response = await $axios.post('/subscriber',formData);
      if (response.status === 200) {
        setFormData('');
        setsubMsg('Subscribed');
        toast.success("Subscribed Successfully!");
      }else{
        // setsubMsg('Already been taken.');
        toast.error("Already been taken.");
      }
    } catch (error) {
      // setsubMsg('Already been taken.');
      toast.error("Already been taken.");
      console.error('Error Subscriber:', error);
    }
    
  }
  

  if (!settings) {
    return null;
  }
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <Toaster position='bottom-center' reverseOrder={false} />
      <footer className='footer-area bg-black bg-cover'>
        <div className='footer-subscribe'>
          <div className='container'>
            <div
              className='footer-subscribe-inner bg-cover'
              style={{ backgroundImage: `url("${appUrl}/assets/img/bg/6.png")` }}
            >
              <div className='row'>
                <div className='col-lg-6'>
                  <h2 className='mb-lg-0 mb-3'>{getSettingValueByKey(settings,'subscribe_head')}</h2>
                </div>
                <div className='col-lg-6 align-self-center text-lg-end'>
                  <input type='text' placeholder='Your e-mail address' name="email" onChange={handleChange} />
                  <button className='btn btn-black border-radius-0' type="button" onClick={handleSubSubmit} > {subMsg != '' ? subMsg : 'Submit now'}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src={webimage.footer_logo_image_url} alt='img' />
                </div>
                <div className='details'>
                  <p>{getSettingValueByKey(settings,'footer_text')}</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Our Service</h4>
                <ul>

                  {service.map((data,index)=>(
                    <li key={data?.id}>
                      <Link to={`/service/${data.id}`}><FaArrowRight /> {data.name}</Link>
                    </li>
                  ))}

                </ul>
              </div>
            </div>
            {/* neeed to over write */}
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Pay links</h4>
                <ul>
                  {section.map((data,index)=>(
                    <li key={index}>
                      <Link to={`/section/${data.id}`}>
                        <FaArrowRight /> {data.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_about'>
                <h4 className='widget-title'>Contact us</h4>
                <div className='details'>
                    <p className='mt-3'>
                      <FaPhoneAlt /> {getSettingValueByKey(settings,'contact_number')}
                    </p>
                    <p className='mt-2'>
                      <FaEnvelope /> {getSettingValueByKey(settings,'contact_email')}
                    </p>
                    <ul className='social-media'>
                    {social.map((data,index)=>(
                        <li key={data?.id}>
                          <a href={data.link}>
                            <FaFacebookF />
                            {/* <i className="fas fa-facebook"></i> */}
                          </a>
                        </li>
                    ))}
                    </ul>
                </div>
                  
              </div>
            </div>
             {/* neeed to over write */}
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 align-self-center text-center'>
                <p>{getSettingValueByKey(settings,'copy_right_text')}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
