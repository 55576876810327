import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import CaseStudyDetails from "./pages/Gallery/CaseStudyDetails";
import Contact from "./pages/Contact";
import HomeOne from "./pages/Home";
import ServiceDetails from "./pages/Service/ServiceDetails";
import ServiceOfDetails from "./pages/Service/ServiceOfDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import MenuDetails from "./pages/MenuDetails";
import SubMenuDetails from "./pages/SubMenuDetails";
import CaseStudyOfDetails from "./pages/Gallery/CaseStudyOfDetails";
import Section from "./pages/Section";
import Product from "./pages/Product";
import ProductDetails from "./pages/ProductDetails";
import ShoppingCart from "./pages/ShoppingCart";
import CartPayment from "./pages/CartPayment";
import ThankYou from "./pages/ThankYou";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
   
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<HomeOne />} />
          <Route exact path='/menu/:menuId/:slug' element={<MenuDetails />} />
          <Route exact path='/sub-menu/:subMenuId/:slug' element={<SubMenuDetails />} />
          
          <Route exact path='/service/:id' element={<ServiceDetails />} />
          <Route exact path='/service-detail/:id' element={<ServiceOfDetails />} />

          <Route exact path='/gallery/:id' element={<CaseStudyDetails />} />
          <Route exact path='/gallery-detail/:id' element={<CaseStudyOfDetails />} />

          <Route exact path='/blog-detail/:id' element={<BlogDetails />} />
          <Route exact path='/blog-category/:id' element={<Blog />} />

          <Route exact path='/section/:id' element={<Section />} />

          <Route exact path='/contact' element={<Contact />} />

          {/* <Route exact path='/about' element={<About />} />
          <Route exact path='/blog' element={<Blog />} />
          <Route exact path='/blog-details' element={<BlogDetails />} />
          <Route exact path='/case-study-details' element={<CaseStudyDetails />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/service' element={<Service />} />
          <Route exact path='/service-details' element={<ServiceDetails />} />
          <Route exact path='/team' element={<Team />} />
          <Route exact path='/team-details' element={<TeamDetails />} /> */}

          <Route exact path='/products' element={<Product />} />
          <Route exact path='/product-category/:id' element={<Product />} />
          <Route exact path='/product-details/:slug' element={<ProductDetails />} />
          <Route exact path='/shopping' element={<ShoppingCart />} />
          <Route exact path='/cart-payment' element={<CartPayment />} />

          <Route exact path='/thank-you' element={<ThankYou />} />

        </Routes>
        <ScrollToTop smooth color='#246BFD' />
      </BrowserRouter>
   
  );
}

export default App;
