import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { fetchServices } from "../scripts/serviceList";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";

const ServiceAreaOne = () => {
  const settings = useContext(GeneralSettingsContext);
 
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if(!services?.length){
        const data = await fetchServices();
        setServices(data);
      }
    };

    fetchData();
  }, []);

  if (!settings) {
    return null;
  }
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-100'>
        <img
          className='position-bottom-left top_image_bounce'
          src='assets/img/icon/4.png'
          alt='img'
        />
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>{getSettingValueByKey(settings,'service_head')}</h6>
            <h2 className='title'>
              {getSettingValueByKey(settings,'service_title')}
            </h2>
          </div>
          <div className='row'>
            {services.map((data, index) => (
              <div className='col-lg-4 col-md-6' key={index}>
                <div className='single-service-inner text-center'>
                  <div className='thumb'>
                    <img src={data.image_url} alt='img' />
                  </div>
                  <div className='details'>
                    <h5>
                      <Link to={`/service/${data.id}`}>{data.name}</Link>
                    </h5>
                    <p>{data.short_detail}</p>
                    <Link className='btn btn-border-base' to={`/service/${data.id}`}>
                      Touch More <FaPlus />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaOne;
