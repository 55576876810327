import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import CaseStudyArea from "../components/CaseStudyArea";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import { Link, useLocation, useParams } from "react-router-dom";
import $axios from "../axios";
import Layout from "../components/layout/FrontLayout";
import { FaArrowLeft, FaArrowRight, FaTrash } from "react-icons/fa";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";

const CartPayment = ({webimage}) => {

  const settings = useContext(GeneralSettingsContext);
  const [cartProducts, setCartProducts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [gateways, setGateway] = useState([]);
  const [formData, setFormData] = useState({});
  const [subTotal, setSubtotal] = useState('');
  const [totalDiscount, setTotalDis] = useState('');
  const [gatewayId, setGatewayId] = useState('');
  const [delFee, setDelFee] = useState('');
  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
        await carts();
        await getCountries();
        cal();
        fetchGatewayData();
    };

    const fetchGatewayData = async () =>{
        const response = await $axios.get('/gateway');
        setGateway(response.data);
    }

  const carts = async () => {
    setCartProducts(JSON.parse(localStorage.getItem('cartItems')));
  };
 
  const getDel = async () => {
    if (settings) {
        setDelFee(getSettingValueByKey(settings,'delivery_fee'));
    }else{
        setDelFee('0');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
    if(e.target.name === 'gateway_id'){
        setGatewayId(e.target.value);
        reset({ gateway_id: e.target.value });
    }
  };

  const getCountries = async () => {
    const response = await fetch('/json/countries.json');
    const data = await response.json();
    setCountries(data);
  };

  const onSubmit = async (formData) => {
    // e.preventDefault();
    try{
        formData['item'] = cartProducts;
        formData['gateway_id'] = gatewayId;
        const response = await $axios.post('/submit-order',formData);
      
        if (response.status === 200) {

            if(response.data.success && response.data.type && response.data.type === "form"){
                const container = document.getElementById('form-container');
                // Set the form HTML
                container.innerHTML = response.data.redirect_url;
                // Submit the form
                document.getElementById('payment-form').submit();
                localStorage.removeItem('cartItems');
                return false;
            }

            if(response.data.success){
                localStorage.removeItem('cartItems');
                window.location.href = response.data.redirect_url;
                return false;
            }else{
                toast.error(response.data?.message);
              }
              
          }else{
            toast.error("Please try again, Something went wrong");
          }
    }catch (error) {
        if (error.response.data.error) {
            for (const [key, messages] of Object.entries(error.response?.data.error)) {
              setError(key, { type: 'server', message: messages[0] });
            }
        }else{
            const errorMessage = error.response?.status === 422 ? 
            Object.values(error.response.data.error).flat()[0] :
            error.response?.data.error || 'Something went wrong';
            console.error('Error Order Submit:', errorMessage);
        }
            
      }
    
  }

  const cal = () => {
    var totalDiscount = 0;
    var subTotal = 0;

    if (cartProducts && cartProducts.length > 0) {
        cartProducts.forEach((item) => {
            if (item.item && item.item.price && item.item.discount_amount) {
                if(item.item.price > 0){
                    subTotal += parseFloat(item.item.price)*parseFloat(item.qty);
                }else{
                    subTotal = 0;
                }

                if(item.item.discount_amount > 0){
                    totalDiscount += parseFloat(0);
                    // totalDiscount += parseFloat(item.item.discount_amount);
                }else{
                    totalDiscount = 0;
                }
            }
        });
        setSubtotal(subTotal);
        setTotalDis(totalDiscount);
        getDel();
    }
  };

    useEffect(() => {
        cal();
    }, [cartProducts]); // Recalculate when cartProducts change

    useEffect(() => {
        cal();
    }, [settings]); // Recalculate when settings change
  
  if (!settings) {
    return null;
  }

 

  return (
    <>
      <Layout>

        {/* Navigation Bar */}
        <Breadcrumb title={'Cart Payment'} webimage={webimage} />

        <section className="cart-personal pd-top-120 pd-bottom-120">
            <div id="form-container"></div>
            <div className="container container-two">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row gy-5">
                        <div className="col-lg-6 pe-sm-5">
                            <div className="cart-personal__content bg-gray p-lg-4 p-2 rounded ">
                                <h4 className=" mb-4 cart-personal__title mb-32">Billing Address</h4>
                                    <div className="mb-4 single-input-inner">
                                        <label for="name" className="form-label font-16 mb-2 fw-500 font-heading">
                                            Full Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            value="Jhon deo"
                                            type="text"
                                            className="common-input"
                                            style={errors.name ? { border: "1px solid red" } : {}}
                                            id="name"
                                            placeholder="Name"
                                            name="name"
                                            maxLength={100}
                                            onChange={handleChange}
                                            {...register("name", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 100,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.name && <small className="text-danger">{errors.name?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="email" className="form-label font-16 mb-2 fw-500 font-heading">Email Address <span className="text-danger">*</span> </label>
                                        <input
                                            value="user@user.com"
                                            type="email"
                                            className="common-input"
                                            style={errors.email ? { border: "1px solid red" } : {}}
                                            id="email"
                                            placeholder="Email"
                                            name="email"
                                            maxLength={50}
                                            onChange={handleChange}
                                            {...register("email", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 50,
                                                message: "This input exceeds max length 100.",
                                            },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address."
                                            }
                                            })}
                                        />
                                        {errors.email && <small className="text-danger">{errors.email?.message}</small>}
                                    </div>
                                
                                    <div className="mb-4 single-input-inner">
                                        <label for="mobileNumber" className="form-label font-16 mb-2 fw-500 font-heading">Mobile Number <span className="text-danger">*</span> </label>
                                        <input
                                            value="01246467457"
                                            type="tel"
                                            className="common-input"
                                            style={errors.mobile ? { border: "1px solid red" } : {}}
                                            id="mobile"
                                            placeholder="Mobile"
                                            name="mobile"
                                            maxLength={13}
                                            onChange={handleChange}
                                            {...register("mobile", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 11,
                                                message: "This input exceeds max length 11.",
                                            },
                                            pattern: {
                                                value: /^\d+$/,
                                                message: "This input is number only.",
                                            },
                                            })}
                                        />
                                        {errors.mobile && <small className="text-danger">{errors.mobile?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="homeAddress" className="form-label font-16 mb-2 fw-500 font-heading">Address <span className="text-danger">*</span> </label>
                                        <input
                                            value="H 70, Jev"
                                            type="text"
                                            className="common-input"
                                            style={errors.address ? { border: "1px solid red" } : {}}
                                            id="address"
                                            name="address"
                                            placeholder="eg: house , area"
                                            maxLength={191}
                                            onChange={handleChange}
                                            {...register("address", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 191,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.address && <small className="text-danger">{errors.address?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="stateThana" className="form-label font-16 mb-2 fw-500 font-heading">State/Thana <span className="text-danger">*</span> </label>
                                        <input
                                            value="Tej"
                                            type="text"
                                            className="common-input"
                                            style={errors.state ? { border: "1px solid red" } : {}}
                                            id="state"
                                            name="state"
                                            placeholder="State"
                                            maxLength={100}
                                            onChange={handleChange}
                                            {...register("state", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 100,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.state && <small className="text-danger">{errors.state?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="city" className="form-label font-16 mb-2 fw-500 font-heading">City <span className="text-danger">*</span> </label>
                                        <input
                                            value="Dhaka"
                                            type="text"
                                            className="common-input"
                                            style={errors.city ? { border: "1px solid red" } : {}}
                                            id="city"
                                            placeholder="City"
                                            maxLength={100}
                                            name="city"
                                            onChange={handleChange}
                                            {...register("city", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 100,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.city && <small className="text-danger">{errors.city?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="disDivision" className="form-label font-16 mb-2 fw-500 font-heading">District/Division <span className="text-danger">*</span> </label>
                                        <input
                                            value="Dhaka"
                                            type="text"
                                            className="common-input"
                                            style={errors.division ? { border: "1px solid red" } : {}}
                                            id="division"
                                            name="division"
                                            placeholder="eg: house , area"
                                            maxLength={100}
                                            onChange={handleChange}
                                            {...register("division", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 100,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.division && <small className="text-danger">{errors.division?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="country" className="form-label font-16 mb-2 fw-500 font-heading">Country <span className="text-danger">*</span> </label>
                                        <select 
                                            className="form-control" 
                                            style={errors.country ? { border: "1px solid red" } : {}}
                                            name="country" 
                                            id="country" 
                                            onChange={handleChange} 
                                            {...register("country", {
                                                required: "This field is required.",
                                            })}>
                                            <option value="">Select...</option>
                                            {countries.map((data,index)=>(
                                                <option value={data.name} key={index} selected={index === 30}>{data.name}</option>
                                            ))}
                                        </select>
                                        {errors.country && <small className="text-danger">{errors.country?.message}</small>}
                                    </div>

                                    <div className="mt-32 mb-32">
                                        <div className="common-check d-flex align-items-center gap-2 mb-2">
                                            <input checked className="form-check-input mt-0" type="checkbox" name="agree" id="agree" onChange={handleChange}
                                            {...register("agree", {
                                                required: "You have to agree to terms.",
                                            })}/>
                                            <label className="form-check-label mb-0" for="agree">Agree To Terms</label>
                                            {errors.agree && <small className="text-danger">* {errors.agree?.message}</small>}
                                        </div>
                                        <div className="common-check d-flex align-items-center gap-2 mb-0">
                                            <input checked className="form-check-input mt-0" type="checkbox" name="privacy" id="privacy"  onChange={handleChange}
                                            {...register("privacy", {
                                                required: "You have to agree to Privacy policy.",
                                            })}/>
                                            <label className="form-check-label mb-0" for="privacy">Agree To Privacy Policy</label>
                                            {errors.privacy && <small className="text-danger">* {errors.privacy?.message}</small>}
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="order-summary">
                                <h4 className=" mb-4 order-summary__title mb-32">Order Summary</h4>
                                <ul className="billing-list">
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading fw-500">You have items</span>
                                        <span className="amount text-heading fw-500">{cartProducts.length}</span>
                                    </li>
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading fw-500">Discount</span>
                                        <span className="amount text-body">{getSettingValueByKey(settings,'currency')} {totalDiscount}</span>
                                    </li>
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading fw-500">Delivery Fee</span>
                                        <span className="amount text-body">{getSettingValueByKey(settings,'currency')} {delFee}</span>
                                    </li>
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading fw-500">Subtotal</span>
                                        <span className="amount text-body">{getSettingValueByKey(settings,'currency')} {subTotal}</span>
                                    </li>
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading font-20 fw-500 font-heading">Total</span>
                                        <span className="amount text-heading font-20 fw-500 font-heading"> {getSettingValueByKey(settings,'currency')} {(parseFloat(subTotal)+parseFloat(delFee)).toFixed(2)}</span>
                                    </li>
                                </ul>


                                <div className="payment-method mt-5">
                                    <h5 className="payment-method__title mb-4">Select Payment Method</h5>
                                    <div className="row">
                                        {gateways && gateways.map((data,index) => (
                                            <div className="col-3 mt-1" key={index}>
                                                <div className="payment-method__item">
                                                    <input className="form-check-input" type="radio" name="gateway_id" id={`payment${data.id}`} value={data.id} hidden onChange={handleChange} 
                                                    />
                                                    <label className="form-check-label" htmlFor={`payment${data.id}`}>
                                                        <img className="img-fluid img-thumbnail" width={60} src={data.image_url} alt={data.name}/>
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                        {errors.gateway_id && <small className="text-danger mt-2">* {errors.gateway_id?.message}</small>}
                                    </div>
                                </div>

                                <button type="submit" className="btn btn--black pill btn-lg w-100"> 
                                    <span className="icon icon-left"><img src="assets/images/icons/cart-reverse.svg" alt=""/></span> 
                                    Purchase Now
                                </button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </section>

      </Layout>
    </>
  );
};
export default CartPayment;
