import React, { useContext, useEffect, useState } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { FaCalendarAlt, FaRegComments, FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { fetchBlogs } from "../scripts/blogList";

const BlogAreaOne = () => {
  const settings = useContext(GeneralSettingsContext);
  const [blogs, setBlogs] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchBlogs();
      setBlogs(data);
     
    };

    fetchData();
  }, []);

 if (!settings) {
  return null;
  }
  return (
    <>
      {/*===================== Blog Area One start =====================*/}
      <div className='blog-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>{getSettingValueByKey(settings,'blog_head')}</h6>
            <h2 className='title'>
            {getSettingValueByKey(settings,'blog_title')}
            </h2>
          </div>
          <div className='row'>

           {blogs.map((data,index)=>(
              <div key={index} className='col-lg-4 col-md-6'>
               <div className='single-blog-list'>
                 <div className='thumb'>
                   <img src={data.image_url} alt='img' />
                 </div>
                 <div className='details'>
                   <p className='date mb-3'>
                     <FaCalendarAlt />
                     {formatDate(data.updated_at)}
                   </p>
                   <h5>
                     <Link to={`/blog-detail/${data.id}`}>{data.name}</Link>
                   </h5>
                 </div>
               </div>
             </div>
           ))}

          </div>
        </div>
      </div>
      {/* ===================== BlogAreaOne End =====================*/}
    </>
  );
};

export default BlogAreaOne;
